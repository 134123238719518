import React from "react"
import Helmet from 'react-helmet'
import '../assets/scss/main.scss'
import CitfContentSection from '../components/CitfContentSection'
import CtaSection from '../components/CTASection'
import GlobalValues from '../components/GlobalValues'

class CitfPage extends React.Component {
  render() {
    return (
      <div>
        <Helmet title="建造業創新及科技基金 (CITF)" >
        {
          <script async src={GlobalValues.gtag_link}></script>
        }
        {
          <script>
            {GlobalValues.gtag_config}
          </script>
        }

        </Helmet>

        <CitfContentSection />
        <CtaSection />
      </div>
    )
  }
}
export default CitfPage
