import React, { Component, Fragment} from 'react'
import Collapse, { Panel } from 'rc-collapse'

export default class BudMoreInfoSection extends Component {
    render() {
        return (
            <div className="col-12">
            <header className="major">
                <h3>更多資料</h3>
            </header>
                <Collapse accordion={true}>
                    <Panel header="提交申請的所需文件" showArrow={false}>
                        <ul>
                            <li>1. 商業登記證副本</li>
                            <li>2. 最新周年申報表</li>
                            <li>3. 三個月內的公司銀行戶口結單</li>
                            <li>4. 註冊專門行業承造商編號 / 建造業徵款承 建商號碼，如適用</li>
                            <li>5. 法定機構名稱及法例章節編號，如適用
                                <ul>
                                    <li>5.1 法定組織類型證明文件，如適用</li>
                                </ul>
                            </li>
                            <li>6. 公司資料</li>
                            <li>7. 公司聯絡人資料</li>
                            <li>8. 管理層代表資料</li>
                            <li>9. 公司銀行戶口資料</li>
                            <li>10. 股東資料（如個別股權者持有 30%或以上 的股份）</li>
                        </ul>
                    </Panel>
                    <Panel header="計劃涵蓋的項目列表" showArrow={false}>
                        <b>科技應用</b>
                        <ul>
                            <li>建築信息模擬培訓、建築信息模擬試用及建築信息模擬項目應用；</li>
                            <li>創新建築科技，例如：自動化、工業化和數碼化的技術；</li>
                            <li>聘請專業的「組裝合成」建築法、購買／租用「組裝合成」建築法指定建造設備，及購買／場外生產「組裝合成」建築法模組；以及</li>
                            <li>預製鋼筋。</li>
                        </ul>
                        <b>人力發展</b>
                        <ul>
                            <li>為學生提供本港以外的進修課程，開支包括：培訓費用、培訓期間的住宿費用、機票及行政費用；</li>
                            <li>為從業員提供本港以外的培訓／考察，開支包括：培訓費用及行政費用的開支；</li>
                            <li>在香港舉辦國際會議，開支包括：場地費用、講者的機票及住宿，及籌備活動的行政費用；以及</li>
                            <li>本地合辦課程，開支包括：場地費用、講者的機票及住宿，及籌備課程的行政費用。</li>
                        </ul>
                    </Panel>
                    <Panel header="資助用途" showArrow={false}>
                        <b>科技應用</b>
                        <ul>
                            <li>基金將用以支援經證實能促進生產力、提高建造質素、改善工地安全或提升環保效益的科技（包括機械、設備及軟件 ）。這些科技必須是利用自動化、工業化和數碼化的技術，而並非單純是創新的營運管理方式。</li>
                        </ul>
                        <b>人力發展</b>
                        <ul>
                            <li>為提升從業員掌握科技的能力，藉以持續改善本港建造業質素，基金將資助現有從業員及希望投身建造業的專才參與有關先進建築科技的課程及活動。</li>
                        </ul>
                    </Panel>
                    <Panel header="資助金額" showArrow={false}>
                        <ul>
                            <li>每位申請者在項目應用的上限為港幣600,000元；而試用項目則為港幣200,000元；</li>
                        </ul>
                    </Panel>
                    <Panel header="申請撥款及收款程序" showArrow={false}>
                        <ul>
                            <li>成功獲批資助的企業可透過基金電子申請平台提出撥款發放申請。</li>
                        </ul>
                    </Panel>
                </Collapse>
            </div>
        )
    }
}