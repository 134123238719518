import React from 'react'
import MoreInfo from './CitfMoreInfoSection'

const budContentSection = ({ system, elements }) => (
  <section className="main style1">
    <div className="grid-wrapper">
      <div className="col-12">
        <header className="major">
          <h3>建造業創新及科技基金 (CITF)</h3>
        </header>
        <p>香港特別行政區政府發展局（發展局）獲批撥款港幣10億元，在2018年10月成立建造業創新及科技基金（基金）。發展局委託建造業議會（議會）為基金執行伙伴。基金鼓勵廣泛採用創新建築方法及科技，促進生產力、提高建造質素、改善工地安全及提升環保效益。</p>
        <MoreInfo />
      </div>
    </div>
  </section>
)

export default budContentSection